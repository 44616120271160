/* ========== main-slider ========== */
.main-slider {
	padding-top: 20px;
	&__img {
		position: relative;
		overflow: hidden;
		height: 450px;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: auto;
			min-width: 100%;
			max-width: none;
			height: auto;
			min-height: 100%;
			max-height: none;
			transform: translate(-50%, -50%);
		}
	}
	.slick-arrow {
		z-index: 1;
		&:after {
			content: "";
			position: absolute;
			left: -19px;
			top: -12px;
			border-radius: 50%;
			width: 60px;
			height: 60px;
			border: 2px solid #726b6d;
		}
		&:hover:after {
			border-color: #4d264d;
		}
	}
	.slick-prev {
		left: 100px;
	}
	.slick-next {
		right: 100px;
	}
}
.slick-dots {
	position: absolute;
	bottom: 25px;
	display: block;
	width: 100%;
	padding: 0 70px 0 0;
	margin: 0;
	list-style: none;
	text-align: right;
}
.slick-dots li {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}
.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 20px;
	height: 20px;
	padding: 5px;
	cursor: pointer;
	color: transparent;
	border: 2px solid #726b6d;
	outline: none;
	background: transparent;
	border-radius: 50%;
	transition: border-color .15s, color .15s;
}
.slick-dots .slick-active button,
.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
	background-color: #4d264d;
	border-color: #4d264d;
}
.slick-dots .slick-active button:hover {
	cursor: default;
}
@media (max-width: 767px) {
	.slick-dots {
		padding: 0;
		text-align: center;
	}
}

